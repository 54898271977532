import _ from 'underscore';
import $ from 'legacy/jquery';
import { Validator } from 'legacy/validation';

// Encapsulates a section of a form that has a single, required text field.
//
// The section container must have a submit-path data attribute.
//
// Required options:
//   - container: a selector for the section container
//   - textField: a selector for the text field
//   - submitButton: a selector for the submit button
//   - errorContainer: a selector for the error message container
//   - validationMessage: a message to display when the text field is left blank
//
// Optional options:
//   - beforeShow: function to call when this section is activated
//
class Section {
  constructor(options) {
    const getRequiredOption = (name) => {
      if (options[name]) {
        return options[name];
      } else {
        throw 'Missing required option: ' + name;
      }
    };

    this.$container = $(getRequiredOption('container'));
    this.$textField = $(getRequiredOption('textField'));
    this.$submitButton = $(getRequiredOption('submitButton'));
    this.$errorContainer = $(getRequiredOption('errorContainer'));
    this.validationMessage = getRequiredOption('validationMessage');
    this.beforeShow = options.beforeShow || _.noop;
  }

  value() {
    return this.$textField.val();
  }

  clear() {
    this.$textField.val('');
    this.clearError();
  }

  focus() {
    this.$textField.focus();
  }

  validator() {
    const validator = new Validator();
    validator
      .validate(this.$textField)
      .withMessage(this.validationMessage)
      .notBlank();
    return validator;
  }

  submitPath() {
    return this.$container.data('submit-path');
  }

  enableSubmitState() {
    this.$submitButton.addClass('loading');
  }

  disableSubmitState() {
    this.$submitButton.removeClass('loading');
  }

  setError(message) {
    this.$errorContainer.text(message);
    this.$errorContainer.show();
  }

  clearError() {
    this.setError('');
    this.$errorContainer.hide();
  }

  hide() {
    this.$container.hide();
  }

  show() {
    this.beforeShow();
    // Don't use .show() here. If the element has no "display" attribute, .show() does nothing. But
    // the password section is given "display: none" in our CSS, which will not be changed with .show()
    this.$container.css('display', 'block').focus();
    this.focus();
  }

  // Swaps this section with another by sliding them sideways.
  // Accepts a slide direction of 'left' or 'right'.
  swapWith(otherSection, direction, onComplete) {
    this.$container.hide('slide', { direction: direction }, 100, function () {
      otherSection.$container.show(
        'slide',
        { direction: direction === 'right' ? 'left' : 'right' },
        100,
        onComplete
      );
    });
  }
}

export default Section;
