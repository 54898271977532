import _ from 'underscore';

class SectionSet {
  constructor() {
    this.sections = {};
    this.positions = {};
    this.sectionCount = 0;
    this.mode = null;
  }

  addSection(mode, section) {
    this.sectionCount++;
    this.sections[mode] = section;
    this.positions[mode] = this.sectionCount;
  }

  showSection(mode) {
    _.each(this.sections, function (section, sectionMode) {
      if (sectionMode === mode) {
        section.show();
      } else {
        section.hide();
      }
    });
  }

  switchToSection(mode) {
    const newSection = this.sections[mode];
    const direction =
      this.positions[mode] > this.positions[this.mode] ? 'left' : 'right';

    newSection.beforeShow();
    this.sections[this.mode].swapWith(
      newSection,
      direction,
      newSection.focus.bind(newSection)
    );
  }

  setMode(mode) {
    this.mode = mode;
    this.section = this.sections[mode];
  }

  initMode(mode) {
    if (mode !== this.mode) {
      this.setMode(mode);
      this.showSection(mode);
    }
  }

  switchToMode(mode) {
    if (mode !== this.mode) {
      this.switchToSection(mode);
      this.setMode(mode);
    }
  }
}

export default SectionSet;
