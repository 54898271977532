import documentReady from 'document-ready';
import Ajax from 'legacy/ajax';
import $ from 'legacy/jquery';
import 'legacy/jquery/jq_ext';
import Message from 'legacy/message';

import Section from './login/section';
import SectionSet from './login/section_set';

let $form;
const sectionSet = new SectionSet();

function init() {
  initState();
  registerEvents();
}

function initState() {
  $form = $('#sign_in_form');

  const emailSection = new Section({
    container: '#email_section',
    textField: '#user_email',
    submitButton: '#submit_email_button',
    errorContainer: '#submit_email_error',
    validationMessage: 'Please enter your email',
    beforeShow: function () {
      passwordSection.clear();
    },
  });
  const passwordSection = new Section({
    container: '#password_section',
    textField: '#user_password',
    submitButton: '#submit_password_button',
    errorContainer: '#submit_password_error',
    validationMessage: 'Please enter your password',
    beforeShow: function () {
      $('#email_display').text(emailSection.value());

      displaySsoRedirectButton(
        $form.attr('data-org-soft-enabled'),
        $form.attr('data-sso-without-subdomain-feature-flag-enabled'),
        $form.attr('data-idp-url')
      );
    },
  });

  sectionSet.addSection('email', emailSection);
  sectionSet.addSection('password', passwordSection);
  sectionSet.initMode($form.attr('data-mode'));
}

function registerEvents() {
  $form.validateOn('submit', validate, submit, { scroll: false });
  $('#back_to_email_form').on('click', goBack);

  function goBack() {
    sectionSet.switchToMode('email');

    const $buttonWrapper = $('.button-wrapper');
    if ($buttonWrapper.is(':hidden')) {
      $buttonWrapper.show();
      $('.sso-soft-enabled-button-wrapper').hide();
    }
  }
}

function validate() {
  return sectionSet.section.validator();
}

function submit() {
  const section = sectionSet.section;
  section.clearError();
  section.enableSubmitState();

  $form.attr('action', section.submitPath());

  Ajax.submit({
    formSelector: $form,
    disableFlash: true,
    success: onSuccess,
    error: onError,
  });

  return false;
}

function onSuccess(response) {
  if (response && response.goto) {
    return;
  }

  if (sectionSet.mode === 'email') {
    sectionSet.section.disableSubmitState();
    sectionSet.switchToMode('password');

    displaySsoRedirectButton(
      response.org_soft_enabled,
      response.sso_without_subdomain_enabled,
      response.idp_url
    );
  }
}

function onError(response, xhr) {
  const message = getErrorMessage(response, xhr);
  sectionSet.section.setError(message);
  sectionSet.section.disableSubmitState();
}

function getErrorMessage(response, xhr) {
  if (response && response.status) {
    if (response.invitation_expired) {
      return Message.Login.InvitationExpired;
    } else if (response.invited) {
      return Message.Login.AlreadyInvited;
    } else if (response.locked) {
      return Message.Login.UserLocked;
    } else {
      return Message.Login.InvalidCredentials;
    }
  } else if (xhr && xhr.status === 429) {
    return Message.Login.TooManyRequests;
  } else {
    return Message.Login.Error;
  }
}

function displaySsoRedirectButton(
  orgSoftEnabled,
  ssoWithoutSubdomainEnabled,
  idpUrl
) {
  if (orgSoftEnabled && ssoWithoutSubdomainEnabled) {
    $('.button-wrapper').hide();
    $('.sso-soft-enabled-button-wrapper').show();
    $('#continue-to-sso').attr('href', idpUrl);
  }
}

documentReady(() => {
  init();
});
